import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { strapiPublicEndpoints } from "./endpoints";
import Navigation from "./navigation/Navigation";
import * as CmsActions from "./store/actions/cms";

function App() {
  // log our data to the console so that we can read it
  const dispatch = useDispatch();
  // const { data } = useSelector((state) => state.cms);
  // console.log({ ...data });

  // fetch all of our data on app load from Strapi.
  // To edit what endpoints are accessed, edit the ./endpoints.js file
  useEffect(() => {
    strapiPublicEndpoints.forEach((el) => dispatch(CmsActions.fetchCms(el.slug, el.populate)));
  }, []);

  // Display our app navigator component from ./navigation/Navigation.js
  return <Navigation />;
}

export default App;
