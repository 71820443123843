import React from "react";
import { Link } from "react-router-dom";

const CTA = ({ heading, body, button }) => {
  return (
    <div className="section cc-cta">
      <div className="container">
        <div className="cta-wrap">
          <div>
            <div className="cta-text">
              <div className="heading-jumbo-small">
                {heading}
                <br />
              </div>
              <div className="paragraph-bigger cc-bigger-light">
                {body}
                <br />
              </div>
            </div>
            {button && (
              <Link to={button.slug} className="button cc-jumbo-button w-inline-block">
                <div>{button.text} →</div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
