// This component is the base for every page displayed on the screen
// It ensures that each page has the head tags, footer, navbar, and Banner componentes
import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";

import Banner from "./Banner";
import Footer from "./Footer";
import Head from "./Head";
import Navbar from "./Navbar";
import MarketingBanner from "./MarketingBanner";

function Page(props) {
  // change <html> tag attributes
  useEffect(() => {
    // if were in dev mode and wf jhtml attributes arent set, let dev know we have a problem
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (!props["data-wf-page"] || !props["data-wf-site"])
        console.warn("Did you forget to set your HTML attibutes on your <Page> component? This will cause problems with Webflow animations. - Liam");
    }

    // fix webflow animation bug
    var html = document.getElementsByTagName("html")[0];
    var node = ReactDOM.findDOMNode(html);
    node.setAttribute("data-wf-page", props["data-wf-page"]);
    node.setAttribute("data-wf-site", props["data-wf-site"]);

    try {
      window.Webflow && window.Webflow.destroy();
      window.Webflow && window.Webflow.ready();
      window.Webflow && window.Webflow.require("ix2").init();
      document.dispatchEvent(new Event("readystatechange"));
    } catch (error) {
      console.warn("Error destroying and reinitializing Webflow Animations. Some of your animations may not work properly.");
    }
  }, []);

  return (
    <>
      <Banner />
      <Head />
      <Navbar />
      {props.children}
      <MarketingBanner />
      <Footer />
    </>
  );
}

export default Page;
