import React from "react";
import Page from "../components/Page";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";
import Accordion from "../components/Accordion";
import { Link } from "react-router-dom";
import CTA from "../components/CTA";

function IndexPage() {
  // access our data so that we can display it
  const singleTypeSlug = "home-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;
  const faqData = data["faq"] && data["faq"].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  return (
    <Page data-wf-page="6585d290ddfcf25db5afef8f" data-wf-site="6585d28fddfcf25db5afef13">
      <div className="section cc-store-home-wrap">
        <div className="intro-header">
          <div className="intro-content cc-homepage">
            <div className="intro-text">
              <img src={cmsData?.hero_logo?.data?.attributes?.url || "images/ASC_logo-12.png"} loading="lazy" alt="" className="image" />
            </div>
            {cmsData?.hero_button && (
              <Link to={cmsData?.hero_button?.slug} className="button cc-jumbo-button secondary w-inline-block">
                <div>{cmsData?.hero_button?.text} →</div>
              </Link>
            )}
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="motto-wrap">
            <div className="label cc-light">{cmsData?.blurb_small}</div>
            <div className="heading-jumbo-small">
              {cmsData?.blurb_large}
              <br />
            </div>
          </div>
          <div className="divider" />
        </div>
      </section>
      <section>
        <div id="w-node-f778a075-971d-b6b3-8dab-a121c3747186-b5afef8f" className="w-layout-layout quick-stack image-stack wf-layout-layout">
          <div id="w-node-_1645f8b4-cb02-7dfb-0cea-0725b52dc4d3-b5afef8f" className="w-layout-cell">
            <img src={cmsData?.images_1?.data?.attributes?.url || "images/christian-mackie-cc0Gg3BegjE-unsplash.jpg"} loading="lazy" className="image-2" />
          </div>
          <div id="w-node-b3be4c60-ed3b-5262-f46b-656b7856aa0b-b5afef8f" className="w-layout-cell">
            <img src={cmsData?.images_2?.data?.attributes?.url || "images/sidekix-media-I_QC1JICzA0-unsplash.jpg"} loading="lazy" className="image-3" />
            <img src={cmsData?.images_3?.data?.attributes?.url || "images/roam-in-color-zzMb7jacyBc-unsplash.jpg"} loading="lazy" className="image-4" />
          </div>
        </div>
      </section>
      <section className="section faq">
        <div className="w-layout-blockcontainer container w-container">
          <h3 className="heading-jumbo-tiny">{cmsData.faqs_heading}</h3>
          <div className="w-dyn-list">
            <Accordion data={faqData?.items} />
          </div>
        </div>
      </section>
      <CTA heading={cmsData.cta_heading} body={cmsData.cta_body} button={cmsData.cta_button} />
    </Page>
  );
}

export default IndexPage;
