import React, { Fragment } from "react";
import Page from "../components/Page";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { Carousel } from "react-responsive-carousel";
import CTA from "../components/CTA";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function ProductsPage() {
  // access our data so that we can display it
  const singleTypeSlug = "products-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return null;

  return (
    <Page data-wf-page="6585d290ddfcf25db5afef99" data-wf-site="6585d28fddfcf25db5afef13">
      <section className="small-hero">
        <div className="w-layout-blockcontainer w-container">
          <div className="small-hero-content">
            <div className="dot" />
            <h1 className="heading small-hero-heading">{cmsData.hero_heading}</h1>
            <div className="dot" />
          </div>
        </div>
      </section>
      <div className="textblock subpage">
        <div className="w-container">
          <div className="text-block-5">
            <br />
            {cmsData.hero_description}
          </div>
        </div>
      </div>
      <div className="stickysubnav">
        <div className="container-5 w-container">
          {cmsData?.products?.data?.map((el, i) => {
            let item = el?.attributes;
            return (
              <Fragment key={uuid()}>
                <a href={`#${item?.title}`} className="onpagelink">
                  {item?.title}
                </a>
                {i + 1 !== cmsData?.products?.data?.length && <div className="bluedot" />}
              </Fragment>
            );
          })}
        </div>
      </div>
      {cmsData?.products?.data?.map((el, i) => {
        let item = el?.attributes;
        return (
          <div key={uuid()} id={item.title} className="product-section">
            <div className="w-container">
              <h2 className="heading2-subtle orange">{item.title}</h2>
              <div className={`producttextslidercont ${i % 2 === 0 && "odd"}`}>
                <div className={`text-block-3  ${i % 2 === 0 && "odd"}`}>{item.description}</div>
                <div className="slider-4" style={{ height: 300 }}>
                  <Carousel showThumbs={false} autoPlay={true} interval={4000} infiniteLoop={true} showStatus={false} stopOnHover={true} animationHandler="fade">
                    {item?.images?.data?.map((el, i) => {
                      return <img key={uuid()} src={el.attributes.url} style={{ height: 300, objectFit: "cover" }} />;
                    })}
                  </Carousel>
                </div>
              </div>
              {i + 1 !== cmsData?.products?.data?.length && <div className="line" />}
            </div>
          </div>
        );
      })}

      <CTA heading={cmsData.cta_heading} body={cmsData.cta_body} button={cmsData.cta_button} />
    </Page>
  );
}

export default ProductsPage;
