// Our Navbar component
// This will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Navbar() {
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "navigation-bar";
  const navData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!navData) return null;
  let lastLink = navData.links[navData.links.length - 1];

  return (
    <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navigation w-nav">
      <div className="navigation-wrap">
        <Link to="/" className="logo-link w-nav-brand w--current">
          <img src={(navData.logo.data && navData.logo.data.attributes.url) || "images/ASC_logo-11.png"} loading="lazy" alt="" className="logo-image" />
        </Link>

        <div className="menu">
          <nav role="navigation" className="navigation-items w-nav-menu">
            {navData.links &&
              navData.links.map((item, index) => {
                if (index === navData.links.length - 1) return null;
                return (
                  <Link key={uuid()} to={item.slug} className={` navigation-item w-nav-link ${window.location.pathname === item.slug && "w--current"}`}>
                    {item.text}
                  </Link>
                );
              })}

            <Link to={lastLink.slug} className=" hideonsmall button cc-contact-us mobile w-inline-block">
              <div>{lastLink.text}</div>
            </Link>
          </nav>
          <div className="menu-button w-nav-button">
            <img src="images/menu-icon_1menu-icon.png" width={22} alt="" className="menu-icon" />
          </div>
        </div>
        <Link to={lastLink.slug} className="button cc-contact-us w-inline-block">
          <div>{lastLink.text}</div>
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
