import React, { useEffect } from "react";
import WebFont from "webfontloader";
import { Helmet } from "react-helmet";

function Head() {
  useEffect(() => {
    // If your head code includes any google fonts, place them here
    WebFont.load({
      google: {
        families: [
          "Josefin Sans:100,200,300,regular,500,600,700,100italic,200italic,300italic,italic,500italic,600italic,700italic",
          "Archivo:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic",
        ],
      },
    });
  }, []);

  //   Depending on the page we're on, we'll want different head tags. make a 'case' for each page slug,
  //   Convert your webflow <head></head> code to JSX and paste it between these <Helmet></Helmet> tags
  switch (window.location.pathname) {
    case "/":
      return (
        <Helmet>
          <meta charset="utf-8" />
          <title>ALL SURFACES COUNTERTOPS</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
      );
    case "/products":
      return (
        <Helmet>
          <meta charset="utf-8" />
          <title>ALL SURFACES COUNTERTOPS | Products</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
      );
    case "/contact":
      return (
        <Helmet>
          <meta charset="utf-8" />
          <title>ALL SURFACES COUNTERTOPS | Contact</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
      );

    default:
      return (
        <Helmet>
          <meta charset="utf-8" />
          <title>ALL SURFACES COUNTERTOPS | Error</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
        </Helmet>
      );
  }
}

export default Head;
