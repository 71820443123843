// This file defines the strapi endpoints you want to pull dtata from (collections & single types)
// This data will be fetched every time the page is loaded or refreshed

// slug = database table name, also known as API ID (Plural)
// fields = what columns from the table do you want to use?
// populate = how to show nested components & relations (doc: https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields)
// There's two formats for doing this. Populate can be a string or qs.stringify()

import qs from "qs";

export const strapiPublicEndpoints = [
  // Single Types
  {
    slug: "navigation-bar",
    populate: "populate=*",
  },
  {
    slug: "footer",
    populate: "populate=*",
  },
  {
    slug: "home-page",
    populate: "populate=*",
  },
  {
    slug: "pop-up",
    populate: qs.stringify({
      populate: {
        content: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "contact-page",

    populate: qs.stringify({
      populate: {
        locations: {
          populate: {
            hours: {
              populate: "*",
            },
          },
        },
      },
    }),
  },
  {
    slug: "products-page",
    populate: qs.stringify({
      populate: {
        products: {
          populate: {
            images: {
              populate: "*",
            },
          },
        },
        cta_button: {
          populate: "*",
        },
      },
    }),
  },

  // Collections
  {
    slug: "faq",
    populate: "populate=*",
  },
];

// An example for getting complicated with the populate feild:
