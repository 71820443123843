import React from "react";
import Page from "../components/Page";
import { v4 as uuid } from "uuid";
// your page that has the form must include this import line.
// This is a function that submits whatever data is in your form to strapi,
// where it is stored in the database and emailed out to all notification recipients
import { useSubmitForm } from "../hooks/useSubmitForm";
import { useSelector } from "react-redux";

// Each input in your form must have a unique name attribute e.x. name="Email"
// your form tag must have the onSubmit={useSubmitForm} attribute
function ContactPage() {
  const { handleSubmit } = useSubmitForm();
  // access our data so that we can display it
  const singleTypeSlug = "contact-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return null;
  console.log(cmsData);
  return (
    <Page data-wf-page="6585d290ddfcf25db5afef94" data-wf-site="6585d28fddfcf25db5afef13">
      <section className="small-hero">
        <div className="w-layout-blockcontainer w-container">
          <div className="small-hero-content">
            <div className="dot" />
            <h1 className="heading small-hero-heading">{cmsData.hero_heading}</h1>
            <div className="dot" />
          </div>
        </div>
      </section>
      <section className="section">
        <div className="w-layout-blockcontainer container w-container">
          <div className="form-block w-form">
            <form
              action="/"
              onSubmit={handleSubmit}
              id="email-form"
              name="email-form"
              data-name="Email Form"
              data-wf-page-id="6585d290ddfcf25db5afef94"
              data-wf-element-id="a17d1680-19b8-a557-c584-15ed52aba90b"
            >
              <div id="w-node-_5a9dedc5-254a-8f24-2237-05d6b3227abf-b5afef94" className="w-layout-layout quick-stack-2 wf-layout-layout">
                <div id="w-node-b8531f29-710c-42bd-8774-ce3957a929e9-b5afef94" className="w-layout-cell">
                  <input type="text" className="input w-input" maxLength={256} name="name" data-name="Name" placeholder="Name" id="name" />
                </div>
                <div id="w-node-_81ac8fca-0ed7-38f0-3dce-25af030832db-b5afef94" className="w-layout-cell">
                  <input type="tel" className="input w-input" maxLength={256} name="Phone" data-name="Phone" placeholder="Phone Number" id="Phone" required="" />
                </div>
                <div id="w-node-f98006c8-d536-333d-cf13-21f2ce2c9596-b5afef94" className="w-layout-cell">
                  <input type="email" className="input w-input" maxLength={256} name="email" data-name="Email" placeholder="Email" id="email" required="" />
                </div>
                <div id="w-node-_87c34a48-f224-ef1d-e9c8-21ad660522b2-b5afef94" className="w-layout-cell">
                  <textarea placeholder="How can we help you?" maxLength={5000} id="message" name="message" data-name="Message" className="input text-area w-input" defaultValue={""} />
                </div>
                <div id="w-node-_2cd74549-aa2d-c4d9-4b68-d5b49c5785d1-b5afef94" className="w-layout-cell" />
                <div id="w-node-_42dcb1b0-e938-0108-ace3-36683a9cddac-b5afef94" className="w-layout-cell">
                  <input type="submit" defaultValue="Send →" className="button submit w-button" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="section map">
        <div className="w-layout-blockcontainer container w-container">
          <h2 className="heading-2">{cmsData.map_heading}</h2>
          {cmsData?.locations?.map((el) => {
            return (
              <div className="map-hours">
                <iframe
                  className="map-2 w-widget w-widget-map"
                  src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_API}
    &q=${el?.map_url}`}
                />
                <div className="hours">
                  <div className="label">Hours of Operation</div>
                  {el?.hours?.map((el) => {
                    return (
                      <div key={uuid()} className="hours-row">
                        <div>{el.key}</div>
                        <div>{el.value}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </Page>
  );
}

export default ContactPage;
