import React from "react";
import Page from "../components/Page";

function ErrorPage() {
  return (
    <Page>
      <div className="errorPage">
        <h1>Oops Something Went Wrong!</h1>
      </div>
    </Page>
  );
}

export default ErrorPage;
