// Our Footer component
// Thsi will need to be modified to match the JSX coming from webflow

import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Footer() {
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "footer";
  const footerData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!footerData) return null;
  return (
    <div className="section footer">
      <div className="container">
        <div className="footer-wrap">
          <div className="footer-nav-links">
            {footerData.links &&
              footerData.links.map((item, index) => {
                return (
                  <Fragment key={uuid()}>
                    <Link key={uuid()} to={item.slug} className="link footer-nav-link ">
                      {item.text}
                    </Link>
                    {index !== footerData.links.length - 1 && <div className="dot" />}
                  </Fragment>
                );
              })}
          </div>
          <a href="https://www.bellwoodsweb.com" target="_blank" className="link bellwoods">
            Made by Bellwoods Web Development
          </a>
          <img src={footerData?.logo?.data?.attributes?.url || "images/ASC_Logo-12.svg"} loading="lazy" alt="" className="footer-logo" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
