// Weblfow Accordions tend not to work well once exported
// This allows us to have a Strapi CMS linked Accordion
// May need some modificatons to match your webflow code structure

import React, { useState } from "react";
import RichText from "./RichText";
import { v4 as uuid } from "uuid";
import "../css/Accordion.css";

const Accordion = ({ data }) => {
  if (!data) return null;
  return (
    <div className="Accordion w-dyn-items">
      {data.map((el) => {
        return <AccordionItem key={uuid()} heading={el.heading} body={el.body} />;
      })}
    </div>
  );
};

const AccordionItem = ({ heading, body }) => {
  const [open, setOpen] = useState(false);

  return (
    <div role="listitem" className={` faqitem w-dyn-item AccordionItem ${open ? "active" : null}`}>
      <div className="div-block-2 AccordionHeader" onClick={() => setOpen(!open)}>
        <div className="text-block">{heading}</div>
        <img src="images/chevron-down-icon.svg" loading="lazy" alt="" className="image-5" />
      </div>
      <div className="text-block-2 AccordionBody">{body}</div>
    </div>
  );
};

export default Accordion;
